import * as React from 'react'

const NotFoundPage = () => {
    return(
        <div>
            <h1>Page Not Found</h1>
        </div>
    )
}

export default NotFoundPage