import * as React from 'react'
import {Box, Button, Center, chakra, Flex, Heading, Stack} from "@chakra-ui/react";
import {Card} from "../../atoms/Card";
import {PasswordField} from "../../atoms/PasswordField";
import {Auth} from "aws-amplify";
import {ICredentials} from "aws-amplify/lib/Common/types/types";
import {useHistory} from "react-router-dom";

const ChangePasswordPage: React.FC = () => {

    const [current, setCurrent] = React.useState("")
    const [newPass, setNewPass] = React.useState("")
    const [confirmNewPass, setConfirmNewPass] = React.useState("")

    const [user, setUser] = React.useState<any | null>(null)

    const history = useHistory()

    React.useEffect(() => {
        Auth.currentAuthenticatedUser().then(e => {
            setUser(e)
        }).catch(() => history.push("/users/login"))
    })

    return (
        <Flex style={{width: '100%'}}>
            <Center flexDir={"column"} alignContent={"center"} style={{width: '100%'}}>
                <Box style={{marginBottom: 18}}>
                    <Heading>Change Password</Heading>
                </Box>
                <Card>
                    <chakra.form onSubmit={e => {
                        e.preventDefault()
                        Auth.changePassword(user, current, newPass)
                    }}>
                        <Stack spacing="6">
                            <PasswordField value={current} onChange={e => setCurrent(e.target.value)}/>
                            <PasswordField value={newPass} onChange={e => setNewPass(e.target.value)}/>
                            <PasswordField value={confirmNewPass} onChange={e => setConfirmNewPass(e.target.value)}/>
                            <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
                                Change Password
                            </Button>
                        </Stack>
                    </chakra.form>
                </Card>
            </Center>
        </Flex>
    )
}

export default ChangePasswordPage