import * as React from 'react'
import {Box, Button, Link, useDisclosure, useToast} from "@chakra-ui/react";
import './Navigation.css'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react"
import {useHistory} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import LoginButton from "../atoms/LoginButton";

interface NavigationMoleculeProps {
    sidebarOpen: boolean,
    onToggle: () => void
}

const Navigation: React.FC<NavigationMoleculeProps> = ({sidebarOpen, onToggle}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {user, isAuthenticated, isLoading} = useAuth0()

    return (
        <>
            {/*<Button onClick={onOpen}>Menu</Button>*/}
            <Drawer isOpen={isOpen} onClose={onClose} onEsc={onClose}>
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerHeader borderBottomWidth={'1px'}>MyFans Contact</DrawerHeader>
                    <DrawerCloseButton/>

                </DrawerContent>
            </Drawer>
            <Box shadow="base" className={sidebarOpen ? 'sidebar sidebar--open' : 'sidebar'}>
                {/*<Button variant={"ghost"} onClick={onToggle}>Open</Button>*/}
                {/*{isAuthenticated ? <SignedInMenu /> : <SignedOutMenu />}*/}
            </Box>
        </>
    )
}

const SignedInMenu = () => {
    const history = useHistory()
    const {logout} = useAuth0()
    return <div>

        <Button onClick={() => logout()}>Sign Out</Button>
        <Button onClick={() => history.push("/users/profile")}>My Profile</Button>
    </div>
}

const SignedOutMenu = () => {
    return <div>
        <LoginButton/>
    </div>
}


export default Navigation
