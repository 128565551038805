import* as React from 'react'
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import Navigation from './molecules/Navigation';
import Home from "./pages/Home/Home";
import NotFoundPage from "./pages/NotFound/NotFound";
import {Heading, Modal, ModalContent, ModalOverlay, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import LoginPage from "./pages/Login/Login";
import { ViewProfilePage } from './pages/Users/Profile/ViewProfile';
import ChangePasswordPage from "./pages/Login/ChangePassword";
import {useAuth0} from "@auth0/auth0-react";
import {observer} from "mobx-react";
import GlobalState from "./state/globalstate";
import { MfcModal } from './atoms/Modal';

const Routes = observer(() => {
    const {user, error, isLoading} = useAuth0()
    const toast = useToast()
    const {isOpen: open2, onToggle} = useDisclosure()
    const [userPopped, setPopped] = React.useState(false)
    React.useEffect(() => {
        if(error) {
            toast({title: error.message, status: 'error'})
        }
        if (user && !userPopped) {
            setPopped(true)
            toast({title: 'Logged In', status: "success"})
        }
    }, [user, error, toast, userPopped, setPopped])
    return(
        <Router>

            <Navigation onToggle={onToggle} sidebarOpen={open2} />
            <div className={open2 ? "main main--sidebar-open" : "main"}>
                <Switch>
                    <Route path={"/users/login"}>
                        <LoginPage />
                    </Route>
                    <Route path={"/users/passwords/change"}>
                        <ChangePasswordPage />
                    </Route>
                    <Route path={"/users/profile"}>
                        <ViewProfilePage />
                    </Route>
                    <Route path={"/"} exact>
                        <Home />
                    </Route>
                    <Route path={"/"}>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </div>
            <MfcModal isOpen={isLoading && GlobalState.authInitialized} onClose={() => void 0}>
                <Heading paddingBottom={8}>Login in progress...</Heading>
                <Spinner size={"xl"} />
            </MfcModal>
            {/*{inProgress !== 'none' ? <div style={{position: 'absolute', left:0, right: 0, top:0, bottom:0, zIndex: 90, backgroundColor: 'red'}}>*/}
            {/*    Logging you in... {inProgress}*/}
            {/*</div> : null}*/}
        </Router>
    )
})

export default Routes
