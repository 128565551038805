import * as React from 'react'
import {
    Box,
    Button,
    Center,
    chakra,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import {PasswordField} from "../../atoms/PasswordField";
import {Card} from "../../atoms/Card";
import {DividerWithText} from "../../atoms/DividerWithText";
import {Auth} from "aws-amplify";

const LoginPage = () => {
    const toast = useToast()
    const [user, setUser] = React.useState(null)
    const[username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    React.useEffect(() => {
        Auth.currentAuthenticatedUser().then(setUser).catch(() => void 0)
    }, [])
    if(user) {
        return(
            <Flex style={{width: '100%'}}>
                <Center flexDir={"column"} alignContent={"center"} style={{width: '100%'}}>
                    <Heading>Logged In</Heading>
                    <Text>You are already logged in with the following accounts</Text>
                    <ul>
                        <Input value={JSON.stringify(user) || ""} />
                    </ul>
                    <Button onClick={() => {Auth.signOut().then(() => toast({title: 'Logged Out'}))}}>Log Out</Button>
                </Center>
            </Flex>
        )
    }
    return (
        <Flex style={{width: '100%'}}>
            <Center flexDir={"column"} alignContent={"center"} style={{width: '100%'}}>
                <Box style={{marginBottom: 18}}>
                    <Heading>Log In</Heading>
                </Box>
                <Card>
                    <chakra.form
                        onSubmit={(e) => {
                            e.preventDefault()
                            Auth.signIn(username, password, {})
                                .then((s) => {
                                    if(s.challengeName === 'NEW_PASSWORD_REQUIRED'){
                                        Auth.completeNewPassword(
                                            s,
                                            prompt("New Password") || "Welkom01"
                                        )
                                    }
                                    toast({title: 'Logged in!', description: JSON.stringify(s)})
                                    setUser(s)
                                }).catch(f => toast({
                                title: 'Could not log in',
                                description: JSON.stringify(f.message)
                            }))
                        }}
                    >
                        <Stack spacing="6">
                            <FormControl id="email">
                                <FormLabel>Email address</FormLabel>
                                <Input name="email" autoComplete="email" value={username} onChange={event => setUsername(event.target.value)} required/>
                            </FormControl>
                            <PasswordField value={password} onChange={event => setPassword(event.target.value)}/>
                            <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
                                Sign in
                            </Button>
                        </Stack>
                    </chakra.form>
                    <DividerWithText mt="6">or continue with</DividerWithText>
                </Card>
            </Center>
        </Flex>
    )
}

export default LoginPage
