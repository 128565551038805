import * as React from 'react'
import {Modal, ModalContent, ModalOverlay} from "@chakra-ui/react";

interface Props {
    isOpen: boolean
    onClose: () => void
}

export const MfcModal: React.FC<Props> = ({isOpen, onClose, children}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent justifyContent={"center"} alignItems={"center"} padding={8}>
                {children}
            </ModalContent>
        </Modal>
    )
}