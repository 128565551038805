import * as React from 'react'
import {useAuth0} from "@auth0/auth0-react";
import "./auth.css"

const CheckEmailConfirm: React.FC = () => {
    const {user, } = useAuth0();
    if(user && !user.email_verified) {
        return <div className={"unverifiedBanner"}>You have not yet verified your e-mail</div>
    } else {
        return null
    }
}

export default CheckEmailConfirm