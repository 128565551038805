import {action, makeObservable, observable} from "mobx";

class GlobalStatePrototype {
    authInitialized: boolean = false

    constructor(authInitialized: boolean) {
        makeObservable(this, {
            authInitialized: observable,
            confirmAuthInitialized: action
        })
    }

    confirmAuthInitialized() {
        this.authInitialized = true
    }
}

const GlobalState = new GlobalStatePrototype(false)

export default GlobalState
