import React from 'react';
import './App.css';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import {Text, Center, Flex, Heading} from "@chakra-ui/react";

function Home() {

    return (
        <Flex className="App">
            <Center flexGrow={1} flexDirection={"column"} justifyContent={"center"}>
                <Heading size={"4xl"} color={"gray.700"}>
                    MyFans
                </Heading>
                <Heading size={"2xl"} color={"gray.700"}>
                    Contact
                </Heading>
                <Text style={{marginTop: 30}}>
                    Coming Soon...
                </Text>
            </Center>
        </Flex>
    );
}

export default Home;
