import configuration from '../configuration.json'

interface TerraformOutput {
    sensitive: boolean
    value: string
    type: string
}

interface TerraformConfiguration {
    [e: string]: TerraformOutput
}

interface IAppConfig {
    [e: string]: string
}

const AppConfig: IAppConfig = {}

Object.keys(configuration).forEach((e) => {
    AppConfig[e] = (configuration as TerraformConfiguration)[e].value;
})

export default AppConfig