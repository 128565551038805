import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import reportWebVitals from './utils/reportWebVitals';
import Routes from "./routes";
import {ChakraProvider} from "@chakra-ui/react"
import {Auth0Provider} from "@auth0/auth0-react";
import AppConfig from "./utils/configuration";
import GlobalState from "./state/globalstate";
import {InitialAuthConfigurator} from "./utils/authUtils";
import CheckEmailConfirm from "./molecules/auth/checkEmailConfirm";

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={AppConfig['auth0_web_domain']} clientId={AppConfig['auth0_web_client_id']}>
            <ChakraProvider>
                <InitialAuthConfigurator />
                <CheckEmailConfirm />
                <Routes/>
            </ChakraProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
