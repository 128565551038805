import * as React from 'react'


interface ViewProfilePageProps {

}

const ViewProfilePage: React.FC<ViewProfilePageProps> = () => {

    return (
        <>
           <div></div>
        </>
    )
}

export {ViewProfilePage}