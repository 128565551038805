import * as React from 'react'
import {observer} from "mobx-react";
import {useAuth0} from "@auth0/auth0-react";
import GlobalState from "../state/globalstate";
import {Global} from "@emotion/react";

const InitialAuthConfigurator = observer(() => {
    const {isLoading} = useAuth0()
    React.useEffect(() => {
        if(!GlobalState.authInitialized && !isLoading){
            console.debug("Completed Auth0 Init")
            GlobalState.confirmAuthInitialized()
        }
    }, [isLoading])
    return (<React.Fragment/>)
})

export {InitialAuthConfigurator}
